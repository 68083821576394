<template>
    <div v-if="view == 'button'" @click="open()" class="col-span-1 button-anchor">
        <div class="flex justify-between">
            {{$t('translations.components.modal-wallet-check-credit.placeholder')}}
        </div>
    </div>

    <div v-if="active" class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 transition-opacity" @click="close()">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
            <div id="modal"
                 class="modal-credit inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                 :class="card_scan_type"
                 role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                <div class="modal-content">

                    <modal-close @close="close()" />

                    <!-- select cashfunction -->
                    <div v-if="page == 'scancardnumber'">
                        <div class="flex justify-between items-center mb-5">
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <h3 class="modal-title text-lg leading-6 font-bold text-2xl" id="modal-headline">
                                    <div>{{$t('translations.components.modal-wallet-check-credit.title')}}</div>
                                </h3>
                            </div>
                        </div>
                        <numpad @inputchanged="cardnumber = $event" :allow-scanner="true"
                                v-if="terminal.wallettoken_default_scan !== 'nfc'"
                                @scanned="processBarcodeScan($event)" />

                        <template v-else>
                            <svg class="nfc-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22 12C22 8.22876 22 6.34315 20.8284 5.17157C19.6569 4 17.7712 4 14 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C21.4816 18.1752 21.7706 17.3001 21.8985 16" stroke="#02a388" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M10 16H6" stroke="#02a388" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M14 16H12.5" stroke="#02a388" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M2 10L7 10M22 10L11 10" stroke="#02a388" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </template> 
                    </div>
                    <!-- confirm cashfunction -->
                    <div v-else>
                        <div class="flex justify-between items-center">
                            <div class="mt-3 sm:mt-0 w-full pr-10">
                                <div class="w-full mb-4">
                                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl uppercase"
                                        id="modal-headline">
                                        {{$t('translations.components.modal-wallet-check-credit.card-number')}}: {{ card.barcode }}
                                    </h3>
                                    <div class="text-lg font-bold">
                                        <span>{{$t('translations.components.modal-wallet-check-credit.current-balance')}}: </span>
                                        <span v-if="this.cashfunction.attributes.coins == false"><currency/></span>
                                        <span>{{ vueNumberFormat(card.balance) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer p-4 sm:px-6 sm:flex sm:flex-row-reverse gap-5" v-if="page !== 'scancardnumber' || terminal.wallettoken_default_scan !== 'nfc'">
                    <span class="mt-3 flex w-full flex justify-between items-center">
                        <div class="flex items-center font-bold" @click="toggleCardType()">
                            <div v-if="!card" class="flex">
                                <span type="button"
                                        :class="[card_scan_type === 'cardnumber' ? 'bg-sjef-purple' : 'bg-sjef-green']"
                                        class="bg-sjef-green relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        role="switch">
                                    <span :class="[card_scan_type === 'chipcard_id' ? 'translate-x-5' : 'translate-x-0']"
                                          class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                                </span>
                                <div class="text-sm ml-2 capitalize hidden sm:block">{{ card_scan_type }}</div>
                            </div>
                        </div>

                        <a v-if="page == 'scancardnumber'" @click="checkCardnumber()"
                           class="button button-filled button-large">
                            {{$t('translations.components.modal-wallet-check-credit.confirm')}}
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'axios';
import md5 from 'js-md5';



// mixins
import NfcReader from '../mixins/nfc.reader.js';

// components
import Numpad from './Numpad';
import ModalClose from "./ModalClose";

export default {
    name: 'ModalWalletAddCredit',
    mixins: [NfcReader],
    emits: ['opened'],
    components: {
        ModalClose,
        Numpad,
    },
    data () {
        return {
            active: false,
            page: 'scancardnumber',
            card: null,
            card_scan_type: 'cardnumber',
            cardnumber: '',
            cashfunctions: null,
            amount: null,
            psp_id: null,
        };
    },

    methods: {
        toggleCardType () {
            if (this.card_scan_type === 'cardnumber') {
                this.card_scan_type = 'chipcard_id';
            } else if (this.card_scan_type === 'chipcard_id') {
                this.card_scan_type = 'cardnumber';
            }
        },

        async open () {
            // disable item barcodescanner
            this.$store.dispatch('settings/itemscanner', false);

            await this.getCashfunction();

            if (!this.cashfunction) {
                return;
            }

            this.card_scan_type = this.terminal.wallettoken_default_scan;

            this.page = 'scancardnumber';
            this.active = true;
            this.$emit('opened');

            this.card = null;
            this.cardnumber = '';
            this.amount = null;

            // todo: remove for production
            // this.barcodeScanned(process.env.VUE_APP_WALLETTOKEN)
            this.$store.dispatch('terminal/setModalActive', true);


            if (this.card_scan_type === 'nfc') {
                await this.readNFC();
            }

        },

        async close () {
            // activate item barcodescanner
            this.$store.dispatch('settings/itemscanner', false);
            this.$store.dispatch('terminal/setModalActive', false);

            if (this.card_scan_type === 'nfc') {
                await this.abortNFC();
            }

            this.active = false;
        },

        // search item on barcode scanner
        processBarcodeScan (barcode) {
            if (this.active === false) {
                return;
            }

            if (barcode.length > 0) {
                this.cardnumber = barcode;

                this.checkCardnumber();
            }
        },

        async checkCardnumber () {
            if (this.cardnumber.length > 0) {

                let data = {
                    type: this.cashfunction.attributes.coins ? 'coins' : 'cash',
                };

                switch (this.card_scan_type) {
                    case 'cardnumber':
                        data.cardnumber = this.cardnumber;
                        break;
                    case 'chipcard_id':
                        data.chipcard_id = this.cardnumber;
                        break;
                    case 'nfc':
                        data.chipcard_id = this.cardnumber;
                        break;
                    default:
                        data.cardnumber = this.cardnumber;
                        break;
                }

                console.log('data', data)

                // todo loading spinner
                await axios.post('/wallet/token/check', data).then(response => {

                    if (response.data.result == 'success') {
                        this.card = response.data;
                        this.page = 'result';
                    } else {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Ongeldig",
                            text: response.data.message,
                        }, 2000);
                    }
                }).catch(error => {
                    if (error.response.status == 404) {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Niet gevonden",
                            text: "Gescande artikel is niet gevonden",
                        }, 2000);
                    } else {
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",
                        }, 2000);
                        console.error(error);
                    }
                });
            }
        },



        submit () {
            if (!this.card) {
                return;
            }

            if (this.amount == '') {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldig",
                    text: "Geef een bedrag op",
                }, 2000);
            }
            let data = {
                cashfunction_id: 1,//this.cashfunction.id,
                wallettoken_id: this.card.id,
                type: 'cashinout',
                parent_id: null,
                item_id: null,
                item_parent_id: null,
                addon: null,
                editable: true,
                composed_child: false,
                quantity: 1,
                quantity_init: 1,
                barcode: '',
                description: 'Opwaarderen', //this.cashfunction.attributes.description,
                kitchen_groceries: [],
                taxrate: 0,
                rate: this.amount,
                rate_without_discount: this.amount,
                discount_amount: 0,
                total: this.amount,//.toFixed(2),
                variant_id: null,
                variant_label: null,
                notes: null,
            };

            data.id = md5(JSON.stringify(data));

            this.$store.dispatch('transaction/addItem', data);

            this.close();
        },

        async setCashfunctions () {
            axios.get('/cashfunctions').then(response => {
                this.cashfunctions = response.data.data;
            }).catch(error => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
                console.error(error);
            });
        },
    },
    computed: {
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
    },
};
</script>
